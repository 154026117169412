<template>
  <div>
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col
              md="6"
              lg="3"
            >
              <b-form-group
                class="form-group-el-select"
                label="Точка отправления"
              >
                <el-select-clearable
                  v-model="filter.firstPoint"
                  class="form-control"
                  name="firstPoint"
                  placeholder="Начните вводить код маршрута"
                  clearable
                  :filterable="true"
                  :remote="true"
                  :remote-method="getDepartmentsList"
                  :loading="loading"
                  @clear="getDepartmentsList"
                  @input="updateFilter('firstPoint')"
                >
                  <el-option
                    v-for="item in firstDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-form-group
                class="form-group-el-select"
                label="Конечная точка"
              >
                <el-select-clearable
                  v-model="filter.lastPoint"
                  class="form-control"
                  name="lastPoint"
                  placeholder="Начните вводить код маршрута"
                  clearable
                  :filterable="true"
                  :remote="true"
                  :remote-method="getDepartmentsList"
                  :loading="loading"
                  @clear="getDepartmentsList"
                  @input="updateFilter('lastPoint')"
                >
                  <el-option
                    v-for="item in lastDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-form-group
                class="form-group-el-select"
                label="Основные габариты ТС"
              >
                <el-select-clearable
                  v-model="filter.dimensionId"
                  class="form-control"
                  name="dimension"
                  placeholder="Начните вводить наименование основного габарита"
                  clearable
                  :filterable="true"
                  :remote="true"
                  :remote-method="getDimensions"
                  :loading="loading"
                  @clear="getDimensions"
                  @input="updateFilter('dimension')"
                >
                  <el-option
                    v-for="item in mainDimensions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-form-group
                class="form-group-el-select"
                label="Дополнительные габариты ТС"
              >
                <el-select-clearable
                  v-model="filter.optionalDimensionIds"
                  class="form-control"
                  name="optionalDimensions"
                  placeholder="Начните вводить наименование дополнительного габарита"
                  clearable
                  :multiple="true"
                  :filterable="true"
                  :remote="true"
                  :remote-method="getDimensions"
                  :loading="loading"
                  @clear="getDimensions"
                  @input="updateFilter('optionalDimensions')"
                >
                  <el-option
                    v-for="item in optionalDimensions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card
      no-body
    >
      <b-card-header>
        Габариты ТС
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
          <b-button
            to="/route-way-dimensions/customer/add"
            size="sm"
            class="ml-1"
            variant="primary"
            :disabled="loading"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading': loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :options="options"
          :columns="columns"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="optionalDimensions"
            slot-scope="props"
          >
            <template v-if="props.row.optionalDimensions">
              <span
                v-for="optionalDimension in props.row.optionalDimensions"
                :key="optionalDimension.id"
              >
                {{ optionalDimension.name }}<br>
              </span>
            </template>
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-b-tooltip.hover.bottom="'Редактировать'"
              :href="`/route-way-dimensions/customer/edit/id${props.row.id}`"
              class="fa fa-pencil fa-lg text-primary table-action-button"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="`/route-way-dimensions/customer/view/id${props.row.id}`"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
            <a
              v-b-tooltip.hover.bottom="'Удалить'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="confirmDeleteDimension(props.row.id)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue';
import notifications from '../../components/mixins/notifications';
import {
  routeWayDimensions,
  routeWayDimensionDelete,
  departmentsList,
  transportVehiclesDimensionsTypes,
} from '../../services/api';
import {queryToUrl, URLToQuery} from '@/services/http';
import {ServerTable, Event} from 'vue-tables-2';
import Vue from 'vue';
import moment from 'moment';

Vue.use(ServerTable);

export default {
  components: {
    ElSelectClearable,
    ServerTable,
    Event,
  },
  mixins: [notifications],
  data() {
    return {
      lastDepartments: [],
      firstDepartments: [],
      mainDimensions: [],
      optionalDimensions: [],
      mainDimension: null,
      optionalDimension: null,
      lastRefreshTime: null,
      filter: {
        firstPoint: '',
        lastPoint: '',
        dimensionId: null,
        optionalDimensionIds: null,
        page: 1,
        limit: 10,
      },
      loading: false,
      columns: [
        'id',
        'firstPoint.code',
        'lastPoint.code',
        'dimension.name',
        'optionalDimensions',
        'actions',
      ],
      data: [],
      options: {
        customFilters: [
          'firstPoint',
          'lastPoint',
          'dimension',
          'optionalDimensions',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return routeWayDimensions(this.$store.state.user.ownerId, Object.assign(data, {...this.filter}));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'id': 'ID',
          'firstPoint.code': 'Точка отправления',
          'lastPoint.code': 'Конечная точка',
          'dimension.name': 'Основные габариты ТС',
          'optionalDimensions': 'Дополнительные габариты ТС',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  mounted() {
    this.getDepartmentsList();
    this.getDimensions();
    const queries = this.$router.currentRoute.query;
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    Event.$on('vue-tables.pagination', (data) => {
      this.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  created() {
    this.initRouteParams();
  },
  methods: {
    onSubmit() {
      for (const prop in this.filter) {
        if (this.filter) {
          const val = this.filter[prop] ? this.filter[prop] : null;
          Event.$emit('vue-tables.filter::' + prop, val);
        }
      }
    },
    updateFilter(field) {
      (field === 'firstPoint') && (this.setFirstPoint());
      (field === 'lastPoint') && (this.setLastPoint());
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        this.updateRouteParams();
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.firstPoint != null) {
        res.firstPoint = this.filter.firstPoint;
      }
      if (this.filter.lastPoint != null) {
        res.lastPoint = this.filter.lastPoint;
      }
      if (this.filter.dimensionId) {
        res.dimensionId = this.filter.dimensionId;
      }
      if (this.filter.optionalDimensionIds) {
        res.optionalDimensionIds = this.filter.optionalDimensionIds;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      if (this.filter.limit && this.filter.limit !== 10) {
        res.limit = this.filter.limit;
      } else {
        delete res.limit;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        this.$nextTick(()=> {
          Object.assign(this.filter, params);
        });
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          this.$nextTick(()=> {
            Object.assign(this.filter, params);
          });
        }
      }
    },
    setFirstPoint() {
      if (this.filter.firstPoint !== '') {
        this.filter.firstPoint = this.firstDepartments.find((item) => item.value == this.filter.firstPoint).text;
      } else {
        this.filter.firstPoint = null;
      }
    },
    setLastPoint() {
      if (this.filter.lastPoint !== '') {
        this.filter.lastPoint = this.lastDepartments.find((item) => item.value == this.filter.lastPoint).text;
      } else {
        this.filter.lastPoint = null;
      }
    },

    async getDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.lastDepartments = this.firstDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async getDimensions() {
      this.loading = true;
      const response = await transportVehiclesDimensionsTypes({limit: 10});
      if (response && response.status === 200) {
        this.optionalDimensions = this.mainDimensions = response.data.items.map((item) => {
          return {value: item.id, text: item.name};
        });
      }
      this.loading = false;
    },
    async getFirstDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.firstDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    confirmDeleteDimension(dimensionId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить габариты?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteDimension(dimensionId);
          }
        });
    },
    async deleteDimension(dimensionId) {
      this.loading = true;
      const response = await routeWayDimensionDelete(this.$store.state.user.ownerId, dimensionId);
      if (response && response.status === 200) {
        this.showSuccess('Габариты удалены');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
  },
};
</script>
